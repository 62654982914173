import axios from "@evercam/shared/api/client/axios"

export const PtzApi = {
  presets: {
    index({ cameraExid }) {
      return axios.get(`/cameras/${cameraExid}/ptz/presets`)
    },
    go({ cameraExid, presetId }) {
      return axios.post(`/cameras/${cameraExid}/ptz/presets/go/${presetId}`)
    },
    home({ cameraExid, isRecordingFromNvr }) {
      if (!isRecordingFromNvr) {
        return axios.post(`/cameras/${cameraExid}/ptz/home`)
      }

      return axios.post(`/cameras/${cameraExid}/ptz/relative`)
    },
    create({ cameraExid, presetName }) {
      return axios.post(`/cameras/${cameraExid}/ptz/presets/create`, {
        presetName,
      })
    },
    update({ cameraExid, presetId, presetName }) {
      // eslint-disable-next-line
      return axios.post(`/cameras/${cameraExid}/ptz/presets/${presetId}/set`, {
        presetName,
      })
    },
    delete({ cameraExid, presetId }) {
      // eslint-disable-next-line
      return axios.delete(`/cameras/${cameraExid}/ptz/presets/${presetId}/remove`);
    },
  },
  controls: {
    move({ cameraExid, direction, isRecordingFromNvr }) {
      if (!isRecordingFromNvr) {
        return axios.post(`/cameras/${cameraExid}/ptz/relative?${direction}=4`)
      }

      return axios.post(
        `/cameras/${cameraExid}/ptz/continuous/start/${direction}`
      )
    },
    zoom({ cameraExid, direction, isRecordingFromNvr }) {
      if (!isRecordingFromNvr) {
        const zoomValue = direction === "in" ? 1 : -1

        return axios.post(
          `/cameras/${cameraExid}/ptz/relative?zoom=${zoomValue}`
        )
      }

      return axios.post(
        `/cameras/${cameraExid}/ptz/continuous/zoom/${direction}`,
        {
          direction,
        }
      )
    },
    stop({ cameraExid }) {
      return axios.post(`/cameras/${cameraExid}/ptz/continuous/stop`)
    },
  },
}
